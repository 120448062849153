import { defineStore } from 'pinia'

export const useAccountStore = defineStore({
	id: 'account',

	state() {
		return {
			auth: {
				metamask: null,
				beacon: null,
			},
			isLoaded: false,

			address: {
				metamask: '',
				beacon: '',
			},

			balance: {
				ethereum: 0,
				tezos: 0,
				usdt: 0,
				tzbtc: 0,
			},

			chainId: null,

			loadingStates: {
				isMetamaskConnecting: false,
				isBeaconConnecting: false,
				isMetamaskSigning: false,
				isBeaconSigning: false,
			},

			isTempleInstalled: false,
		}
	},
	actions: {
		logoutEthereum() {
			this.auth.metamask = null
			this.address.metamask = ''
			this.balance.ethereum = 0
		},
		logoutTezos() {
			this.auth.beacon = null
			this.address.beacon = ''
			this.balance.tezos = 0
			this.balance.usdt = 0
		},
	},
	getters: {
		isAuthenticated() {
			return !!(this.auth.metamask && this.auth.beacon)
		},

		isMetaMaskConnected() {
			return !!(this.address.metamask?.length && this.auth.metamask)
		},
		isMetaMaskAccountConnected() {
			return !!this.address.metamask.length
		},

		isBeaconConnected() {
			return !!(this.address.beacon?.length && this.auth.beacon)
		},
		isBeaconAccountConnected() {
			return !!this.address.beacon.length
		},

		isAllWalletsConnected() {
			return this.isMetaMaskConnected && this.isBeaconConnected
		},

		isMetaMaskInstalled() {
			return typeof window.ethereum !== 'undefined'
		},
	},
})
