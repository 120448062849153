/** MetaMask chains
 * https://docs.metamask.io/guide/ethereum-provider.html#chain-ids
 */
export const chains = {
	'0x1': { name: 'Mainnet' },
	'0x3': { name: 'Ropsten' },
	'0x4': { name: 'Rinkeby' },
	'0x5': { name: 'Goerli' },
	'0x2a': { name: 'Kovan' },
}

export const StepStatus = {
	NOT_STARTED: 'not_started',
	CANCELED: 'canceled',
	PROGRESS: 'progress',
	COMPLETED: 'completed',
	BLOCKED: 'blocked',
}

export const TransactionStatus = {
	UNKNOWN: 'unknown',
	PROGRESS: 'progress',
	COMPLETED: 'completed',
}
