/** Services */
import { chains } from '@/services/constants'
import { Core } from '@/services/sdk'
import { unauthorizeMetamask } from './auth'

const setupMetamaskListeners = (accountStore, notificationsStore) => {
	if (!window.ethereum) return

	const eth = window.ethereum
	const { atomexNetwork } = Core.base.atomex

	eth.on('chainChanged', async (chainId) => {
		const chain = chains[chainId]

		accountStore.chainId = chainId
		accountStore.balance.ethereum = await Core.getEthereumBalance(accountStore.address.metamask)

		if (
			!chain ||
			(atomexNetwork === 'mainnet' && chain.name !== 'Mainnet') ||
			(atomexNetwork === 'testnet' && chain.name !== 'Goerli')
		) {
			notificationsStore.create({
				kind: 'warning',
				title: chain?.name ? `Unsupported chain. We do not work with ${chain.name}` : 'Unsupported chain',
			})
		} else {
			notificationsStore.create({
				kind: 'success',
				title: `${chain.name}`,
			})
		}
	})
	eth.on('accountsChanged', async (accounts) => {
		/**
		 * Disconnect event
		 */
		if (!accounts.length) {
			await unauthorizeMetamask(accountStore.address.metamask, accountStore)

			notificationsStore.create({
				kind: 'warning',
				title: 'Account disconnected. Please, reconnect',
			})
		}

		/**
		 * User switched to another account
		 */
		if (accounts[0] !== accountStore.address.metamask && accounts.length && accountStore.address.metamask) {
			await unauthorizeMetamask(accountStore.address.metamask, accountStore)
			accountStore.address.metamask = accounts[0]

			notificationsStore.create({
				kind: 'warning',
				title: 'Switching to another account, reconnect.',
			})
		}
	})
}

export { setupMetamaskListeners }
