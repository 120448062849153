export const availableTokens = {
	base: {
		eth: {
			coin: 'ETH',
			name: 'Ethereum',
			icon: 'ethereum',
		},
	},
	quote: {
		xtz: {
			coin: 'XTZ',
			name: 'Tezos',
			icon: 'tezos',
		},
		usdt_xtz: {
			coin: 'USDT_XTZ',
			name: 'USDT on XTZ',
			icon: 'usdt',
		},
		tzbtc: {
			coin: 'tzBTC',
			name: 'BTC on XTZ',
			icon: 'tzbtc',
		},
	},
}

export const tokensMetadata = {
	eth: {
		coin: 'ETH',
		displayName: 'ETH',
		description: 'Ethereum',
		icon: 'ethereum',
		blockchain: 'ethereum',
		tokenType: 'native',
	},
	xtz: {
		coin: 'XTZ',
		displayName: 'XTZ',
		description: 'Tezos',
		icon: 'tezos',
		blockchain: 'tezos',
		tokenType: 'native',
	},
	usdt_xtz: {
		coin: 'USDT_XTZ',
		displayName: 'USDT',
		description: 'USDT on XTZ',
		icon: 'usdt',
		blockchain: 'tezos',
		tokenType: 'fa2',
	},
	tzbtc: {
		coin: 'tzBTC',
		displayName: 'tzBTC',
		description: 'BTC on XTZ',
		icon: 'tzbtc',
		blockchain: 'tezos',
		tokenType: 'fa12',
	},
}

export const supportedSymbols = ['XTZ/ETH', 'ETH/USDT_XTZ', 'XTZ/USDT_XTZ']

export const blockchains = {
	ethereum: {
		mainnet: {
			explorer: {
				name: 'Etherscan',
				link: `https://etherscan.io/tx/`,
			},

			rpc: 'https://mainnet.infura.io/v3/7cd728d2d3384719a630d836f1693c5c',
		},
		testnet: {
			explorer: {
				name: 'Etherscan',
				link: `https://goerli.etherscan.io/tx/`,
			},

			rpc: 'https://goerli.infura.io/v3/7cd728d2d3384719a630d836f1693c5c',
		},
	},
	tezos: {
		mainnet: {
			explorer: {
				name: 'TzKT',
				link: 'https://tzkt.io/',
			},

			rpc: 'https://rpc.tzkt.io/mainnet/',
		},
		testnet: {
			explorer: {
				name: 'TzKT',
				link: `https://ghostnet.tzkt.io/`,
			},

			rpc: 'https://rpc.tzkt.io/ghostnet/',
		},
	},
}

export const beaconOptions = {
	name: 'Atomex DEX',
	iconUrl:
		'https://tezostaquito.io/img/favicon.pnghttps://dashboard-assets.dappradar.com/document/1689/atomex-exchanges-eth-logo-166x166_a9b2ee5ed8f1edec6fe7ce13c43100cb.png',
}

export const inputLimits = {
	min: `0.0000000001`,
	max: '1000000000',
}

export const Errors = {
	NOT_ENOUGH_LIQUIDITY: 'not-enough-liquidity',
	NOT_ENOUGH_FUNDS: 'not-enough-funds',
}

/** temp */
export const ABI = [
	{
		anonymous: false,
		inputs: [
			{
				indexed: true,
				internalType: 'bytes32',
				name: '_hashedSecret',
				type: 'bytes32',
			},
		],
		name: 'Activated',
		type: 'event',
	},
	{
		anonymous: false,
		inputs: [
			{
				indexed: true,
				internalType: 'bytes32',
				name: '_hashedSecret',
				type: 'bytes32',
			},
			{
				indexed: false,
				internalType: 'address',
				name: '_sender',
				type: 'address',
			},
			{
				indexed: false,
				internalType: 'uint256',
				name: '_value',
				type: 'uint256',
			},
		],
		name: 'Added',
		type: 'event',
	},
	{
		anonymous: false,
		inputs: [
			{
				indexed: true,
				internalType: 'bytes32',
				name: '_hashedSecret',
				type: 'bytes32',
			},
			{
				indexed: true,
				internalType: 'address',
				name: '_participant',
				type: 'address',
			},
			{
				indexed: false,
				internalType: 'address',
				name: '_initiator',
				type: 'address',
			},
			{
				indexed: false,
				internalType: 'uint256',
				name: '_refundTimestamp',
				type: 'uint256',
			},
			{
				indexed: false,
				internalType: 'uint256',
				name: '_countdown',
				type: 'uint256',
			},
			{
				indexed: false,
				internalType: 'uint256',
				name: '_value',
				type: 'uint256',
			},
			{
				indexed: false,
				internalType: 'uint256',
				name: '_payoff',
				type: 'uint256',
			},
			{
				indexed: false,
				internalType: 'bool',
				name: '_active',
				type: 'bool',
			},
		],
		name: 'Initiated',
		type: 'event',
	},
	{
		anonymous: false,
		inputs: [
			{
				indexed: true,
				internalType: 'bytes32',
				name: '_hashedSecret',
				type: 'bytes32',
			},
			{
				indexed: false,
				internalType: 'bytes32',
				name: '_secret',
				type: 'bytes32',
			},
		],
		name: 'Redeemed',
		type: 'event',
	},
	{
		anonymous: false,
		inputs: [
			{
				indexed: true,
				internalType: 'bytes32',
				name: '_hashedSecret',
				type: 'bytes32',
			},
		],
		name: 'Refunded',
		type: 'event',
	},
	{
		constant: true,
		inputs: [
			{
				internalType: 'bytes32',
				name: '',
				type: 'bytes32',
			},
		],
		name: 'swaps',
		outputs: [
			{
				internalType: 'bytes32',
				name: 'hashedSecret',
				type: 'bytes32',
			},
			{
				internalType: 'address payable',
				name: 'initiator',
				type: 'address',
			},
			{
				internalType: 'address payable',
				name: 'participant',
				type: 'address',
			},
			{
				internalType: 'uint256',
				name: 'refundTimestamp',
				type: 'uint256',
			},
			{
				internalType: 'uint256',
				name: 'countdown',
				type: 'uint256',
			},
			{
				internalType: 'uint256',
				name: 'value',
				type: 'uint256',
			},
			{
				internalType: 'uint256',
				name: 'payoff',
				type: 'uint256',
			},
			{
				internalType: 'bool',
				name: 'active',
				type: 'bool',
			},
			{
				internalType: 'enum Atomex.State',
				name: 'state',
				type: 'uint8',
			},
		],
		payable: false,
		stateMutability: 'view',
		type: 'function',
	},
	{
		constant: false,
		inputs: [
			{
				internalType: 'bytes32',
				name: '_hashedSecret',
				type: 'bytes32',
			},
			{
				internalType: 'address payable',
				name: '_participant',
				type: 'address',
			},
			{
				internalType: 'uint256',
				name: '_refundTimestamp',
				type: 'uint256',
			},
			{
				internalType: 'uint256',
				name: '_payoff',
				type: 'uint256',
			},
		],
		name: 'initiate',
		outputs: [],
		payable: true,
		stateMutability: 'payable',
		type: 'function',
	},
	{
		constant: false,
		inputs: [
			{
				internalType: 'bytes32',
				name: '_hashedSecret',
				type: 'bytes32',
			},
		],
		name: 'add',
		outputs: [],
		payable: true,
		stateMutability: 'payable',
		type: 'function',
	},
	{
		constant: false,
		inputs: [
			{
				internalType: 'bytes32',
				name: '_hashedSecret',
				type: 'bytes32',
			},
		],
		name: 'activate',
		outputs: [],
		payable: false,
		stateMutability: 'nonpayable',
		type: 'function',
	},
	{
		constant: false,
		inputs: [
			{
				internalType: 'bytes32',
				name: '_hashedSecret',
				type: 'bytes32',
			},
			{
				internalType: 'bytes32',
				name: '_secret',
				type: 'bytes32',
			},
		],
		name: 'redeem',
		outputs: [],
		payable: false,
		stateMutability: 'nonpayable',
		type: 'function',
	},
	{
		constant: false,
		inputs: [
			{
				internalType: 'bytes32',
				name: '_hashedSecret',
				type: 'bytes32',
			},
		],
		name: 'refund',
		outputs: [],
		payable: false,
		stateMutability: 'nonpayable',
		type: 'function',
	},
]
