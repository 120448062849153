/* eslint-disable vue/multi-word-component-names */
import { createApp, h } from 'vue'
import { createPinia } from 'pinia'
import { createMetaManager } from 'vue-meta'

import BigNumber from 'bignumber.js'
BigNumber.set({ EXPONENTIAL_AT: 15 })

import App from './App.vue'
import router from './router'

const app = createApp({
	render: () => h(App),
})

/**
 * Use
 */
app.use(router)
app.use(createPinia())
app.use(createMetaManager())

/**
 * Global components
 */
import Icon from '@/components/icons/Icon'
app.component('Icon', Icon)

app.mount('#app')
