import { defineStore } from 'pinia'

/** Services */
import { Core } from '@/services/sdk'

export const useSwapsStore = defineStore({
	id: 'swaps',

	state() {
		return {
			items: [] /** Swaps */,

			isMTLoaded: false /** ethereum transactions */,
			isTTLoaded: false /** tezos transactions */,
		}
	},

	getters: {
		swapsInProgress() {
			return this.items.filter((swap) => ['progress', 'action_required'].includes(Core.getSwapStatus(swap)))
		},

		isFullyLoaded() {
			return this.isMTLoaded && this.isTTLoaded
		},
	},
})
