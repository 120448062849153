/** Services */
import { Core } from '@/services/sdk'
import { bc } from '@/services/broadcast'

/** API: TzKT */
import { fetchTokenBalanceByAccount } from '@/services/api/tzkt'
import { TaquitoBlockchainWallet, Web3BlockchainWallet } from 'atomex-sdk/development'

Core.base.atomex.authorization.events.authTokenExpiring.addListener((expiringAuthToken) => {
	console.log(
		`Auth token of ${expiringAuthToken.address} expires in ${
			(new Date(expiringAuthToken.expired) - Date.now()) / 1000
		} seconds`
	)
})

Core.base.atomex.authorization.events.authTokenExpired.addListener((expiredAuthToken) => {
	console.log(`Auth token of ${expiredAuthToken.address} is expired`)
})

export const requestMetamask = async (accountStore) => {
	try {
		accountStore.loadingStates.isMetamaskConnecting = true
		const requestedAccounts = await window.ethereum.request({ method: 'eth_requestAccounts' })
		accountStore.loadingStates.isMetamaskConnecting = false

		const selectedAddress = requestedAccounts[0]
			? Core.base.web3.utils.toChecksumAddress(requestedAccounts[0])
			: null

		accountStore.address.metamask = selectedAddress

		if (!selectedAddress) return

		accountStore.loadingStates.isMetamaskSigning = true
		await Web3BlockchainWallet.bind(Core.base.atomex, Core.base.web3.currentProvider)
		const authToken = await Core.base.atomex.authorization.authorize({ address: selectedAddress })
		accountStore.loadingStates.isMetamaskSigning = false

		if (!authToken) return

		await applyMetaMaskAuthorization(authToken, accountStore)

		bc.postMessage('authorized')
	} catch (error) {
		console.error(error)
		accountStore.loadingStates.isMetamaskConnecting = false
		accountStore.loadingStates.isMetamaskSigning = false
	}
}

export const applyMetaMaskAuthorization = async (authToken, accountStore) => {
	try {
		accountStore.auth.metamask = authToken

		accountStore.balance.ethereum = await Core.getEthereumBalance(accountStore.address.metamask)
		accountStore.chainId = window.ethereum.chainId
	} catch (error) {
		console.error(error)
	}
}

export const unauthorizeMetamask = async (address, accountStore) => {
	await Core.base.atomex.authorization.unauthorize(address)
	accountStore.logoutEthereum()
}

export const requestBeacon = async (accountStore) => {
	const network = Core.base.atomex.atomexNetwork === 'mainnet' ? 'mainnet' : 'ghostnet'

	accountStore.loadingStates.isBeaconConnecting = true

	await Core.base.beacon.client
		.requestPermissions({
			network: {
				type: network,
			},
		})
		.then(async ({ address }) => {
			accountStore.address.beacon = address

			accountStore.loadingStates.isBeaconConnecting = false
			accountStore.loadingStates.isBeaconSigning = true

			TaquitoBlockchainWallet.bind(Core.base.atomex, Core.base.beacon)

			const authToken = await Core.base.atomex.authorization.authorize({ address })
			accountStore.loadingStates.isBeaconSigning = false

			if (!authToken) return

			await applyBeaconAuthorize(authToken, accountStore)

			bc.postMessage('authorized')
		})
		.catch(() => {
			accountStore.loadingStates.isBeaconConnecting = false
			accountStore.loadingStates.isBeaconSigning = false
		})
}

export const applyBeaconAuthorize = async (authToken, accountStore) => {
	try {
		accountStore.auth.beacon = authToken
		accountStore.balance.tezos = await Core.getTezosBalance(accountStore.address.beacon)
		accountStore.balance.usdt = await fetchTokenBalanceByAccount({
			tokenContract: Core.base.sdk.getCurrencyConfig(`USDT_XTZ`).tokenAddress,
			accountAddress: accountStore.address.beacon,
			atomexNetwork: Core.base.atomex.atomexNetwork,
		})

		Core.setTezosToolkit()
	} catch (error) {
		console.error(error)
	}
}

export const unauthorizeBeacon = async (address, accountStore) => {
	await Core.base.beacon.client.clearActiveAccount()
	await Core.base.atomex.authorization.unauthorize(address)
	accountStore.logoutTezos()
}
