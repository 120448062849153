<script setup>
import { ref, watch, computed } from 'vue'
import { useRoute } from 'vue-router'

/** UI */
import Button from '@/components/ui/Button'
import Tooltip from '@/components/ui/Tooltip'
import { Dropdown, DropdownItem } from '@/components/ui/Dropdown'

/** Services */
import { Core } from '@/services/sdk'
import { requestMetamask, requestBeacon, unauthorizeBeacon } from '@/services/auth'
import { toClipboard } from '@/services/global'
import { chains } from '@/services/constants'

/** Store */
import { useAccountStore } from '@/store/account'
import { useNotificationsStore } from '@/store/notifications'
import { useSwapsStore } from '@/store/swaps'
import core from '@/services/sdk/core.js'

const accountStore = useAccountStore()
const notificationsStore = useNotificationsStore()
const swapsStore = useSwapsStore()

const route = useRoute()

const activeTab = ref('Exchange')

const firstTabEl = ref(null)
const secondTabEl = ref(null)

const handleConnectMetamask = () => {
	if (metamaskButtonState.value.disabled) return

	requestMetamask(accountStore)
}
const metamaskButtonState = computed(() => {
	if (isUnsupported.value) {
		return {
			text: `Unsupported Chain, ${chains[accountStore.chainId].name}`,
			disabled: true,
		}
	}

	if (accountStore.loadingStates.isMetamaskConnecting) {
		return {
			text: 'Connecting...',
			disabled: true,
		}
	} else if (accountStore.loadingStates.isMetamaskSigning) {
		return {
			text: 'Signing...',
			disabled: true,
		}
	} else if (!accountStore.isMetaMaskAccountConnected) {
		return {
			text: 'Connect Metamask',
			disabled: false,
		}
	} else {
		return {
			text: `Sign ${accountStore.address.metamask.slice(0, 4)}...${accountStore.address.metamask.slice(
				accountStore.address.metamask.length - 4,
				accountStore.address.metamask.length
			)}`,
			disabled: false,
		}
	}
})

const handleConnectBeacon = () => {
	if (beaconButtonState.value.disabled) return

	requestBeacon(accountStore)
}
const beaconButtonState = computed(() => {
	if (accountStore.loadingStates.isBeaconConnecting) {
		return {
			text: 'Connecting...',
			disabled: true,
		}
	} else if (accountStore.loadingStates.isBeaconSigning) {
		return {
			text: 'Signing...',
			disabled: true,
		}
	} else if (!accountStore.isBeaconAccountConnected) {
		return {
			text: 'Connect',
			disabled: false,
		}
	} else {
		return {
			text: `Sign ${accountStore.address.beacon.slice(0, 4)}...${accountStore.address.beacon.slice(
				accountStore.address.beacon.length - 4,
				accountStore.address.beacon.length
			)}`,
			disabled: false,
		}
	}
})

const isTestnet = computed(() => core.base.atomex.atomexNetwork === 'testnet')
const handleSwitch = async () => {
	await Core.base.web3.currentProvider.request({
		method: 'wallet_switchEthereumChain',
		params: [{ chainId: '0x5' }],
	})
}

const showSwitchToTestnet = computed(
	() =>
		accountStore.chainId !== '0x5' &&
		location.hostname !== 'dex.atomex.me' &&
		Core.base.atomex.atomexNetwork === 'testnet'
)

const handleCopy = (target) => {
	toClipboard(target)

	notificationsStore.create({
		kind: 'success',
		title: `Copied to clipboard`,
	})
}

const isUnsupported = computed(() => {
	const chain = chains[accountStore.chainId]
	const { atomexNetwork } = Core.base.atomex

	if (!chain) return true

	return (
		(atomexNetwork === 'mainnet' && chain.name !== 'Mainnet') ||
		(atomexNetwork === 'testnet' && chain.name !== 'Goerli')
	)
})

/** Update `activeTab` when navigating */
watch(
	() => route.name,
	() => {
		activeTab.value = route.name
	}
)
</script>

<template>
	<div :class="$style.wrapper">
		<div :class="$style.content">
			<div :class="$style.panel">
				<router-link to="/" :class="$style.logo_link">
					<img src="@/assets/atomex.png" alt="logo" :class="$style.desktop_logo" />
					<img src="@/assets/atomex-symbol.png" alt="logo" :class="$style.mobile_logo" />
				</router-link>

				<div v-if="isTestnet" :class="$style.testnet">Testnet</div>
			</div>

			<div :class="$style.center">
				<div :class="$style.tabs">
					<router-link
						to="/"
						ref="firstTabEl"
						:class="[$style.tab, activeTab == 'Exchange' && $style.active]"
					>
						Exchange
					</router-link>
					<router-link
						to="/swaps"
						ref="secondTabEl"
						:class="[$style.tab, ['Swap', 'Swaps'].includes(activeTab) && $style.active]"
					>
						Swaps
						<div
							v-if="swapsStore.isFullyLoaded && swapsStore.swapsInProgress.length"
							:class="$style.swaps_counter"
						>
							{{ swapsStore.swapsInProgress.length }}
						</div>
					</router-link>
				</div>
			</div>

			<transition name="fade">
				<div v-if="accountStore.isLoaded" :class="$style.btns">
					<a
						v-if="!accountStore.isMetaMaskInstalled"
						href="https://chrome.google.com/webstore/detail/metamask/nkbihfbeogaeaoehlefnkodbefgpgknn"
						target="_blank"
					>
						<Button type="secondary" size="small" rounded block :class="[$style.btn, $style.left]">
							<Icon name="ethereum" size="24" />
							<div>Install MetaMask</div>
						</Button>
					</a>

					<Button
						v-else-if="!accountStore.isMetaMaskConnected"
						@click="handleConnectMetamask"
						type="secondary"
						size="small"
						rounded
						:disabled="metamaskButtonState.disabled"
						:class="[$style.btn, $style.left]"
					>
						<img :src="require('@/assets/currencies/ethereum.svg')" />

						<div>
							{{ metamaskButtonState.text }}
						</div>
					</Button>
					<Dropdown v-else-if="accountStore.chainId">
						<template #trigger>
							<Tooltip :disabled="!isTestnet">
								<Button type="secondary" size="small" rounded :class="[$style.btn, $style.left]">
									<img
										v-if="!isTestnet && !isUnsupported"
										:src="require('@/assets/currencies/ethereum.svg')"
									/>
									<Icon
										v-else
										name="warning"
										size="20"
										:class="[$style.warn_icon, isUnsupported ? $style.red : $style.yellow]"
									/>

									<div>
										{{ accountStore.address.metamask.slice(0, 4) }}
										...
										{{
											accountStore.address.metamask.slice(
												accountStore.address.metamask.length - 4,
												accountStore.address.metamask.length
											)
										}}

										<span v-if="isTestnet || isUnsupported">
											/
											{{
												chains[accountStore.chainId]?.name
													? chains[accountStore.chainId]?.name
													: 'Unknown Chain'
											}}
										</span>
									</div>
								</Button>

								<template v-if="accountStore.chainId === '0x1'" #content>Ethereum Mainnet</template>
								<template v-else #content>Test Network in use.</template>
							</Tooltip>
						</template>

						<template #dropdown>
							<DropdownItem v-if="showSwitchToTestnet" @click="handleSwitch"
								>Switch to Goerli</DropdownItem
							>
							<DropdownItem @click="handleCopy(accountStore.address.metamask)">Copy address</DropdownItem>
						</template>
					</Dropdown>

					<a
						v-if="!accountStore.isTempleInstalled"
						href="https://chrome.google.com/webstore/detail/temple-tezos-wallet/ookjlbkiijinhpmnjffcofjonbfbgaoc"
						target="_blank"
					>
						<Button type="secondary" size="small" rounded block :class="[$style.btn, $style.right]">
							<Icon name="tezos" size="24" />
							<div>Install Temple</div>
						</Button>
					</a>
					<Button
						v-else-if="!accountStore.isBeaconConnected || !accountStore.isBeaconAccountConnected"
						@click="handleConnectBeacon"
						type="secondary"
						size="small"
						rounded
						:disabled="beaconButtonState.disabled"
						:class="[$style.btn, $style.right]"
					>
						<img :src="require('@/assets/currencies/tezos.svg')" />

						<div>
							{{ beaconButtonState.text }}
						</div>
					</Button>
					<Dropdown v-else-if="accountStore.isBeaconConnected">
						<template #trigger>
							<Button type="secondary" size="small" rounded :class="[$style.btn, $style.right]">
								<img :src="require('@/assets/currencies/tezos.svg')" />
								<div>
									{{ accountStore.address.beacon.slice(0, 4) }}
									...
									{{
										accountStore.address.beacon.slice(
											accountStore.address.beacon.length - 4,
											accountStore.address.beacon.length
										)
									}}
								</div>
							</Button>
						</template>

						<template #dropdown>
							<DropdownItem @click="requestBeacon(accountStore)">Change wallet</DropdownItem>
							<DropdownItem @click="unauthorizeBeacon(accountStore.address.beacon, accountStore)"
								>Disconnect</DropdownItem
							>

							<DropdownItem @click="handleCopy(accountStore.address.beacon)">Copy address</DropdownItem>
						</template>
					</Dropdown>
				</div>
			</transition>
		</div>
	</div>
</template>

<style module>
.wrapper {
	position: sticky;
	top: 0;
	z-index: 1000;
}

.content {
	position: relative;
	display: flex;
	align-items: center;
	justify-content: space-between;

	background: var(--app-bg);
	min-height: 75px;
	padding: 0 32px;

	border-bottom: 1px solid var(--ultra-low-emphasis);
}

.content img {
	height: 54px;
	pointer-events: none;
}

.center {
	position: absolute;
	top: 0;
	bottom: 0;
	left: 50%;
	transform: translateX(-50%);
}

.tabs {
	position: relative;

	display: flex;
	align-items: center;
	gap: 32px;

	width: 448px;
	height: 100%;
}

.tab {
	display: flex;
	justify-content: center;
	align-items: center;
	flex: 1;

	height: 100%;
	border-bottom: 2px solid transparent;

	font-size: 18px;
	line-height: 24px;
	font-weight: 400;
	color: var(--medium-emphasis);

	cursor: pointer;
	transition: all 0.2s ease;
}

.tab.active {
	color: var(--high-emphasis);
	border-bottom: 2px solid var(--high-emphasis);
}

.panel {
	display: flex;
	align-items: center;
	gap: 16px;
}

.testnet {
	display: flex;
	align-items: center;

	height: 32px;
	border-radius: 50px;
	padding: 0 16px;

	font-size: 15px;
	line-height: 20px;
	font-weight: 500;
	color: var(--alert);

	background: rgba(255, 168, 0, 0.15);
}

.btns {
	display: flex;
	align-items: center;
	gap: 2px;
}

.btn.left {
	border-radius: 50px 0 0 50px;
}

.btn.right {
	border-radius: 0 50px 50px 0;
}

.btn img {
	width: 20px;
}

.btn span {
	opacity: 0.4;
}

.swaps_counter {
	display: flex;
	align-items: center;
	justify-content: center;

	font-size: 12px;
	line-height: 20px;
	font-weight: 400;
	color: rgba(0, 14, 34, 0.97);

	width: 22px;
	height: 22px;
	border-radius: 50px;
	background: var(--secondary-500);

	margin-left: 10px;
}

.warn_icon.yellow {
	fill: orange;
}

.warn_icon.red {
	fill: var(--error);
}

.mobile_logo {
	display: none;
}

@media (max-width: 1400px) {
	.content {
		align-items: flex-start;

		min-height: 160px;
	}

	.center {
		display: flex;
		align-items: flex-end;
	}

	.tabs {
		height: 50%;
	}

	.logo_link {
		margin-top: 7px;
	}

	.btns {
		margin-top: 17px;
	}

	.desktop_logo {
		display: none;
	}

	.mobile_logo {
		display: flex;
	}
}

@media (max-width: 600px) {
	.btn div {
		display: none;
	}
}

@media (max-width: 500px) {
	.center {
		width: 100%;
	}

	.tabs {
		width: 100%;
	}
}
</style>
