<script setup>
import { ref, watch, nextTick } from 'vue'

/**
 * Composable
 */
import { useOnOutsidePress } from '@/composable/outside'

const props = defineProps({
	forceOpen: Boolean,
	side: {
		type: String,
		default: 'bottom',
	},
})

const emit = defineEmits(['onClose'])

const trigger = ref(null)
const dropdown = ref(null)
const show = ref(false)

watch(
	() => props.forceOpen,
	() => {
		show.value = props.forceOpen.value
	}
)

const toggleDropdown = (event) => {
	event.stopPropagation()

	show.value = !show.value
}
const close = (event) => {
	if (event) event.stopPropagation()

	show.value = false

	emit('onClose')
}

const dropdownStyles = ref({})

let removeOutside
const handleOutside = (e) => {
	if (e.path.find((el) => el.id === 'trigger')) {
		return
	} else {
		close()
	}
}

watch(show, () => {
	if (!show.value) {
		removeOutside()

		document.removeEventListener('keydown', onKeydown)
	} else {
		document.addEventListener('keydown', onKeydown)

		const triggerRect = trigger.value.getBoundingClientRect()

		dropdownStyles.value.right = `${window.innerWidth - triggerRect.x - triggerRect.width}px`

		if (props.side == 'bottom') {
			dropdownStyles.value.top = `${triggerRect.y + triggerRect.height + 6}px`
		}
		if (props.side == 'top') {
			dropdownStyles.value.bottom = `${window.innerHeight - triggerRect.y + 6}px`
		}

		nextTick(() => {
			removeOutside = useOnOutsidePress(dropdown, handleOutside)
		})
	}
})

const onKeydown = (event) => {
	if (event.key == 'Escape') close()
}
</script>

<template>
	<div :class="$style.wrapper">
		<div ref="trigger" id="trigger" @click="toggleDropdown" :class="$style.trigger">
			<slot name="trigger" />
		</div>

		<teleport to="#dropdown">
			<transition name="fastfade">
				<div v-if="show" :class="$style.canvas">
					<transition name="dropdown" appear>
						<div
							ref="dropdown"
							@click="close"
							:class="$style.dropdown"
							:style="{
								...dropdownStyles,
							}"
						>
							<slot name="dropdown" />
						</div>
					</transition>
				</div>
			</transition>
		</teleport>
	</div>
</template>

<style>
.popup-enter-active,
.popup-leave-active {
	transition: all 0.07s ease-out;
}

.popup-enter-from,
.popup-leave-to {
	opacity: 0;
	transform: scale(0.95);
}
</style>

<style module>
.wrapper {
	position: relative;
}

.trigger {
	cursor: pointer;
}

.canvas {
	position: fixed;
	inset: 0;
	z-index: 2000;
}

.dropdown {
	position: absolute;

	padding: 12px 0;
	border-radius: 8px;
	background: var(--deep-blue);
}
</style>
