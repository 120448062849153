import { unique } from '@/services/utils/arrays'

export const toClipboard = (value) => {
	const el = document.createElement('textarea')
	el.value = value
	el.setAttribute('readonly', '')
	el.style.position = 'absolute'
	el.style.left = '-9999px'
	document.body.appendChild(el)
	el.select()
	document.execCommand('copy')
	document.body.removeChild(el)
}

export const symbolsToTokens = (symbols) => {
	return unique(
		symbols
			.map((symbol) => symbol.split('/'))
			.flat()
			.map((token) => token.toLowerCase())
	)
}

export const cropAddress = (adr) => {
	return `${adr.slice(0, 6)}...${adr.slice(adr.length - 6, adr.length)}`
}
