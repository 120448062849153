export let bc = null

export const initBroadcastChannel = () => {
	bc = new BroadcastChannel('authorization')

	bc.onmessage = function (event) {
		console.log(event.data)
		if (['authorized'].includes(event.data)) {
			/** temp solution */
			location.reload()
		}
	}
}
