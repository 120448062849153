<script setup>
/** Store */
import { useNotificationsStore } from '@/store/notifications'
const notificationsStore = useNotificationsStore()

const getNotificationIcon = (notificationKind) => {
	if (!notificationKind) return 'info'

	switch (notificationKind.toLowerCase()) {
		case 'error':
			return 'close'

		case 'warning':
			return 'warning'

		case 'success':
			return 'check'

		case 'info':
			return 'info'

		default:
			break
	}
}

const removeNotification = (id) => {
	notificationsStore.destroy(id)
}
</script>

<template>
	<div :class="$style.wrapper">
		<transition-group name="popup" tag="div">
			<div
				v-for="(notification, i) in notificationsStore.notifications"
				:key="i"
				@click="removeNotification(notification.id)"
				:class="$style.notification"
			>
				<Icon
					:name="getNotificationIcon(notification.kind)"
					size="16"
					:class="[
						$style.icon,
						notification.kind == 'error' && $style.error,
						notification.kind == 'warning' && $style.warning,
						notification.kind == 'info' && $style.info,
						notification.kind == 'success' && $style.success,
					]"
				/>

				<div :class="$style.title">{{ notification.title }}</div>
			</div>
		</transition-group>
	</div>
</template>

<style module>
.wrapper {
	position: fixed;
	top: 86px;
	left: 16px;

	z-index: 1002;
}

.wrapper p {
	display: flex;
	flex-direction: column;
	align-items: center;
}

.notification {
	position: relative;
	box-sizing: border-box;

	width: 322px;
	height: 54px;
	background: #0d1421;
	box-shadow: 1px 4px 10px rgba(13, 20, 33, 0.25);
	border-radius: 12px;
	padding: 0 20px 0 16px;

	display: flex;
	align-items: center;
	gap: 10px;

	margin-right: 16px;
	margin-bottom: 16px;
}

.icon {
	box-sizing: content-box;
}

.icon.warning {
	fill: var(--alert);
	color: var(--alert);
}

.icon.error {
	fill: var(--error);
	color: var(--error);
}

.icon.info {
	fill: var(--primary-500);
}

.icon.success {
	fill: var(--secondary-500);
}

.title {
	font-size: 14px;
	font-weight: 500;
	line-height: 1.4;
	color: var(--high-emphasis);
}
</style>
