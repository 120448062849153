import { AtomexBuilder } from 'atomex-sdk/development'

const authMessage = ({ address }) => `
I read and accept Term of Use (https://atomex.me/terms) and Privacy Policy (https://atomex.me/privacy)

I confirm that I am the owner of the current address: ${address}

The timestamp of signing: 
`

/**
 * @param {import('atomex-sdk').AtomexNetwork} network
 */
export const createAtomex = (atomexNetwork) => {
	return new AtomexBuilder({ atomexNetwork, authorizationManager: { authMessage } }).build()
}
