<script setup>
import { useCssModule } from 'vue'

const props = defineProps({
	size: {
		type: String,
		default: 'medium',
	},
	type: {
		type: String,
		default: 'primary',
	},
	block: {
		type: Boolean,
		default: false,
	},
	disabled: {
		type: Boolean,
	},
	loading: {
		type: Boolean,
	},
	rounded: {
		type: Boolean,
	},
})

const style = useCssModule()

const getStyles = () => {
	const hasCorrectSize = ['large', 'medium', 'small', 'mini'].includes(props.size)

	return [
		style.wrapper,
		style[props.type],
		props.block && style.block,
		hasCorrectSize && style[props.size],
		props.disabled && style.disabled,
		props.rounded && style.rounded,
	]
}
</script>

<template>
	<button :class="[...getStyles(), loading && $style.loading]">
		<slot />
	</button>
</template>

<style module>
.wrapper {
	display: flex;
	align-items: center;
	justify-content: center;
	gap: 6px;
	cursor: pointer;

	color: var(--high-emphasis);
	white-space: nowrap;

	transition: all 0.2s ease;
}

.wrapper span {
	color: var(--text-tertiary);
}

.wrapper.loading {
	opacity: 0.5;
	pointer-events: none;
}

.wrapper.block {
	width: 100%;
	justify-content: center;
}

/** SIZES */
.wrapper.large {
	font-size: 18px;
	line-height: 24px;

	height: 52px;
	border-radius: 12px;
}

.wrapper.medium {
	font-size: 14px;

	height: 42px;
	padding: 0 12px;

	border-radius: 8px;
}

.wrapper.small {
	font-size: 16px;
	line-height: 20px;

	height: 38px;
	min-height: 38px;
	padding: 0 20px;
	border-radius: 8px;
}

.wrapper:active {
	transform: translateY(1px);
}

/** TYPES */
.wrapper.success {
	background: var(--btn-success-bg);
	fill: var(--text-black);
	color: var(--text-black);
}
.wrapper.success:hover {
	background: var(--btn-success-bg-hover);
}

.wrapper.accent {
	background: var(--secondary-400);
	fill: var(--high-emphasis);
	color: rgba(0, 14, 34, 0.97);
}

.wrapper.primary {
	background: var(--primary-900);
	fill: var(--high-emphasis);
}
.wrapper.primary:hover {
	background: var(--primary-800);
}

.wrapper.secondary {
	background: var(--ultra-low-emphasis);
	fill: var(--high-emphasis);
}
.wrapper.secondary:hover {
	background: var(--low-emphasis);
}

/** OTHER */
.wrapper.disabled {
	/* opacity: 0.5; */
	pointer-events: none;
	background: var(--ultra-low-emphasis);
	color: var(--low-emphasis);
}

.wrapper.rounded {
	border-radius: 50px;
}
</style>
