import { defineStore } from 'pinia'

/** Services */
import { fetchPrice } from '@/services/api/prices'

/** Config */
import { tokensMetadata } from '@/services/config'

export const useExchangeStore = defineStore({
	id: 'exchange',

	state() {
		return {
			symbol: {
				base: tokensMetadata.xtz,
				quote: null,
			},

			prices: {
				eth: 0,
				xtz: 0,
				btc: 0,
				usdt: 1,
				isLoaded: false,
			},

			symbols: [],
		}
	},
	actions: {
		async fetchUSDPrices() {
			this.prices.eth = await fetchPrice('ETH', 'USDT', 'binance')
			this.prices.xtz = await fetchPrice('XTZ', 'USDT', 'binance')
			this.prices.btc = await fetchPrice('BTC', 'USDT', 'binance')
			this.prices.usdt = await fetchPrice('USDT', 'USD', 'kraken')

			this.prices.isLoaded = true
		},
	},
})
