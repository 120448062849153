import { createRouter, createWebHistory } from 'vue-router'

const routes = [
	{
		path: '/',
		name: 'Exchange',
		component: () => import(/* webpackChunkName: "bridge" */ '@/views/BridgePage'),
	},
	{
		path: '/swaps',
		name: 'Swaps',
		component: () => import(/* webpackChunkName: "swaps" */ '@/views/TransactionsPage'),
	},
	{
		path: '/swaps/:swapId',
		name: 'Swap',
		component: () => import('@/views/TransactionPage.vue'),
		// beforeEnter: ifAuthenticated,
	},

	{
		path: '/terms',
		name: 'Terms',
		component: () => import(/* webpackChunkName: "terms" */ '@/views/TermsPage'),
	},
	{
		path: '/privacy',
		name: 'Privacy',
		component: () => import(/* webpackChunkName: "privacy" */ '@/views/PrivacyPage'),
	},
	{
		path: '/faq',
		name: 'Faq',
		component: () => import(/* webpackChunkName: "faq" */ '@/views/FaqPage'),
	},
	{
		path: '/disclaimer',
		name: 'Disclaimer',
		component: () => import(/* webpackChunkName: "disclaimer" */ '@/views/DisclaimerPage'),
	},
]

const router = createRouter({
	history: createWebHistory(process.env.BASE_URL),
	routes,
})

export default router
