import { reactive, computed } from 'vue'
import {
	AuthTokenSource,
	legacy as atomexLegacy,
	TaquitoBlockchainWallet,
	Web3BlockchainWallet,
} from 'atomex-sdk/development'

import web3 from 'web3'
import { TezosToolkit } from '@taquito/taquito'
import { BeaconWallet } from '@taquito/beacon-wallet'

import { createAtomex } from './atomex'

/** Services */
import { blockchains, ABI } from '@/services/config'

const base = reactive({
	/**
	 * @type {import('atomex-sdk').Atomex}
	 */
	atomex: null,
	isAtomexReady: false,

	/**
	 * @type {import('web3').default}
	 */
	web3: null,

	/**
	 * @type {import('atomex-sdk').legacy.Atomex}
	 */
	sdk: null,

	helpers: {
		ethereum: null,
		tezos: null,
		tezosFA12: null,
		tezosFA2: null,
	},

	contracts: {
		ethereum: null,
		tezos: null,
		tezosFA12: null,
		tezosFA2: null,
	},

	beacon: null,
	tezosToolkit: null,
})

const isHelpersReady = computed(
	() => base.helpers.ethereum && base.helpers.tezos && base.helpers.tezosFA12 && base.helpers.tezosFA2
)

/** temp solution, ref for mainnet */
// const runAsTestnet =
// 	location.hostname.startsWith('dev.atomex') ||
// 	location.hostname === 'test.dex.atomex.me' ||
// 	location.hostname === 'localhost'
// const atomexNetwork = runAsTestnet ? 'testnet' : 'testnet'
const atomexNetwork = 'mainnet'

base.atomex = createAtomex(atomexNetwork)
base.atomex
	.start()
	.then(() => {
		base.isAtomexReady = true
	})
	.catch((err) => console.error(err))

base.sdk = atomexLegacy.Atomex.create(atomexNetwork)
base.sdk.setAuthorizationManager(base.atomex.authorization)

base.web3 = new web3(web3.givenProvider)

/** Helpers */
const setEthereumHelpers = async () => {
	base.helpers.ethereum = await atomexLegacy.EthereumHelpers.create(
		base.atomex,
		atomexNetwork,
		blockchains.ethereum[atomexNetwork].rpc
	)
}
const setTezosHelpers = async () => {
	base.helpers.tezos = await atomexLegacy.TezosHelpers.create(base.atomex, atomexNetwork, 'XTZ')
	base.helpers.tezosFA12 = await atomexLegacy.FA12Helpers.create(base.atomex, atomexNetwork, 'TZBTC')
	base.helpers.tezosFA2 = await atomexLegacy.FA2Helpers.create(base.atomex, atomexNetwork, 'USDT_XTZ')
}

const options = {
	name: 'Atomex DEX',
	iconUrl: 'https://dex.atomex.me/favicon.ico',
	preferredNetwork: 'ghostnet',
}

const setBeacon = async () => {
	base.beacon = new BeaconWallet(options)

	const account = await base.beacon.client.getActiveAccount()

	if (!account) return { address: null, auth: null }

	await Promise.all([
		TaquitoBlockchainWallet.bind(base.atomex, base.beacon),
		base.atomex.authorization.authorize({
			address: account.address,
			authTokenSource: AuthTokenSource.Local,
		}),
	])

	setTezosToolkit()

	return { address: account.address, auth: base.atomex.authorization.getAuthToken(account.address) }
}

const setMetaMask = async () => {
	const [selectedAddress] = await base.web3.eth.getAccounts()

	if (!selectedAddress) return { address: null, auth: null }

	await Promise.all([
		Web3BlockchainWallet.bind(base.atomex, base.web3.currentProvider),
		base.atomex.authorization.authorize({ address: selectedAddress, authTokenSource: AuthTokenSource.Local }),
	])

	return { address: selectedAddress, auth: base.atomex.authorization.getAuthToken(selectedAddress) }
}

const setTezosToolkit = () => {
	const network = atomexNetwork === 'mainnet' ? 'mainnet' : 'ghostnet'

	base.tezosToolkit = new TezosToolkit(`https://rpc.tzkt.io/${network}/`)
	base.tezosToolkit.setWalletProvider(base.beacon)
}

const initializeEthereumContracts = async () => {
	const { contractAddress: ethereumContractAddress } = base.sdk.getCurrencyConfig('ETH')

	base.contracts.ethereum = new base.web3.eth.Contract(ABI, ethereumContractAddress)
}

const initializeTezosContracts = async () => {
	const { contractAddress: tezosContractAddress } = base.sdk.getCurrencyConfig('XTZ')
	const { contractAddress: tezosFA12ContractAddress } = base.sdk.getCurrencyConfig('TZBTC')
	const { contractAddress: tezosFA2ContractAddress } = base.sdk.getCurrencyConfig('USDT_XTZ')

	base.contracts.tezos = await base.tezosToolkit.wallet.at(tezosContractAddress)
	base.contracts.tezosFA12 = await base.tezosToolkit.wallet.at(tezosFA12ContractAddress)
	base.contracts.tezosFA2 = await base.tezosToolkit.wallet.at(tezosFA2ContractAddress)
}

const getTezosBalance = async (address) => {
	if (!base.tezosToolkit) return 0

	const balance = (await base.tezosToolkit.tz.getBalance(address)).toNumber() / 1000000

	return balance
}

const getEthereumBalance = async (address) => {
	const network = base.atomex.atomexNetwork
	const { chainId } = window.ethereum

	const balance = await base.web3.eth.getBalance(address)

	if ((network === 'mainnet' && chainId === '0x1') || (network === 'testnet' && chainId === '0x5')) {
		return base.web3.utils.fromWei(balance, 'ether')
	} else {
		return 0
	}
}

const getGasPrice = async (unit = 'gwei') => {
	const gasPrice = await base.web3.eth.getGasPrice()
	return base.web3.utils.fromWei(gasPrice, unit)
}

const getEthereumTransactionMetadata = async (tx) => {
	return await base.web3.eth.getTransaction(tx)
}

/** Fees based on selected blockchains */

const getInitiateFees = async (blockchain) => {
	let initiateFees = 0

	if (blockchain === 'ethereum') {
		initiateFees = await base.helpers.ethereum.estimateInitiateFees(`0x0000000000000000000000000000000000000000`)
		initiateFees = base.web3.utils.fromWei(initiateFees.toString(), 'ether')
	} else if (blockchain === 'tezos') {
		initiateFees = await base.helpers.tezos.estimateInitiateFees(
			atomexNetwork === 'mainnet'
				? `tz3NxTnke1acr8o3h5y9ytf5awQBGNJUKzVU`
				: 'tz1PQ1aDt6p7zwFpyBZQr2VnLS3D2yCmq3s1'
		)
		initiateFees = initiateFees / 1000000
	}

	return initiateFees
}

const getRedeemFees = async (blockchain) => {
	let redeemFees = 0

	if (blockchain === 'ethereum') {
		redeemFees = await base.helpers.ethereum.estimateRedeemFees(`0x0000000000000000000000000000000000000000`)
		redeemFees.totalCost = base.web3.utils.fromWei(redeemFees.totalCost.toString(), 'ether')
		redeemFees.rewardForRedeem = base.web3.utils.fromWei(redeemFees.rewardForRedeem.toString(), 'ether')
	} else if (blockchain === 'tezos') {
		redeemFees = await base.helpers.tezos.estimateRedeemFees(
			atomexNetwork === 'mainnet'
				? `tz3NxTnke1acr8o3h5y9ytf5awQBGNJUKzVU`
				: 'tz1PQ1aDt6p7zwFpyBZQr2VnLS3D2yCmq3s1'
		)
		redeemFees.totalCost = redeemFees.totalCost / 1000000
		redeemFees.rewardForRedeem = redeemFees.rewardForRedeem / 1000000
	}

	return redeemFees
}

/** Utils */
const getSwapStatus = (swap, isInitiateTransactionConfirmed) => {
	if (
		['Initiated', 'Involved'].includes(swap.counterParty.status) &&
		new Date().getTime() - new Date(swap.timeStamp).getTime() > 4 * 60 * 60 * 1000
	)
		return 'canceled'

	if (swap.counterParty.status === 'Refunded') return 'refunded'
	if (swap.user.status === 'Redeemed') return 'completed'
	if (swap.user.transactions[1]?.status === 'Canceled') return 'canceled'
	if (swap.user.status === 'Lost') return 'lost'
	if (swap.user.status === 'Jackpot') return 'jackpot'

	if (
		swap.user.transactions[0]?.status !== 'Confirmed' &&
		swap.counterParty.transactions[0]?.status === 'Confirmed' &&
		['Involved', 'Initiated'].includes(swap.user.status) &&
		isInitiateTransactionConfirmed
	)
		return 'action_required'

	if (
		swap.user.transactions[0]?.status === 'Confirmed' &&
		swap.counterParty.transactions[0]?.status === 'Confirmed' &&
		swap.user.transactions[1]?.status !== 'Confirmed' &&
		swap.counterParty.transactions[1]?.status === 'Confirmed' &&
		!!swap.user.requisites.rewardForRedeem.toNumber()
	)
		return 'action_required'

	return 'progress'
}

export default {
	base,
	setEthereumHelpers,
	setTezosHelpers,
	setBeacon,
	setMetaMask,
	initializeEthereumContracts,
	initializeTezosContracts,
	setTezosToolkit,
	getTezosBalance,
	getEthereumBalance,
	getGasPrice,
	getEthereumTransactionMetadata,
	getInitiateFees,
	getRedeemFees,
	isHelpersReady,
	getSwapStatus,
}
