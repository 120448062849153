<script setup>
import { onBeforeMount, watch } from 'vue'
import { TempleWallet } from '@temple-wallet/dapp'
import { useMeta } from 'vue-meta'

/** Base */
import TheHeader from '@/components/base/TheHeader'
import TheFooter from '@/components/base/TheFooter'

/** Portals */
import NotificationsPortal from '@/components/portals/NotificationsPortal'

/** Services */
import { Core } from '@/services/sdk'
import { initBroadcastChannel } from '@/services/broadcast'
import { setupMetamaskListeners } from '@/services/wallets'
import { supportedSymbols } from '@/services/config'

/** Store */
import { useExchangeStore } from '@/store/exchange'
import { useAccountStore } from '@/store/account'
import { useNotificationsStore } from '@/store/notifications'
import { useSwapsStore } from '@/store/swaps'
import { applyMetaMaskAuthorization, applyBeaconAuthorize as applyBeaconAuthorization } from './services/auth'

const exchangeStore = useExchangeStore()
const accountStore = useAccountStore()
const notificationsStore = useNotificationsStore()
const swapsStore = useSwapsStore()

exchangeStore.fetchUSDPrices()

/** Core */
onBeforeMount(async () => {
	if (Core.base.isAtomexReady) {
		setupWallets()
	}

	/** Symbols */
	const allSymbols = await Core.base.atomex.exchangeManager.getSymbols()
	exchangeStore.symbols = allSymbols.filter((symbol) => supportedSymbols.includes(symbol.name))

	/** Init Helpers */
	await Core.setEthereumHelpers()
	await Core.setTezosHelpers()

	/** Setup Listeners */
	setupMetamaskListeners(accountStore, notificationsStore)

	initBroadcastChannel()
})

const setupWallets = async () => {
	try {
		const isMetamaskAvailable = Core.base.web3.eth.givenProvider
		if (isMetamaskAvailable) {
			accountStore.chainId = window.ethereum.chainId
		}

		if (isMetamaskAvailable) {
			const { address, auth } = await Core.setMetaMask()

			if (address) accountStore.address.metamask = address

			if (auth) {
				await applyMetaMaskAuthorization(auth, accountStore)
				swapsStore.items = [
					...swapsStore.items,
					...(await Core.base.atomex.swapManager.getSwaps([auth.address])),
				]
			}
			swapsStore.isMTLoaded = true
		} else {
			notificationsStore.create({
				kind: 'info',
				title: `Metamask wallet was not detected`,
			})
		}

		const isTempleAvailable = await TempleWallet.isAvailable()
		if (isTempleAvailable) {
			accountStore.isTempleInstalled = true

			const { address, auth } = await Core.setBeacon()

			if (address) accountStore.address.beacon = address

			if (auth) {
				await applyBeaconAuthorization(auth, accountStore)
				try {
					swapsStore.items = [
						...swapsStore.items,
						...(await Core.base.atomex.swapManager.getSwaps([auth.address])),
					]
				} catch (error) {
					console.log(error)
				}
			}
			swapsStore.isTTLoaded = true
		} else {
			accountStore.isTempleInstalled = false
			notificationsStore.create({
				kind: 'info',
				title: `Temple wallet was not detected`,
			})
		}

		accountStore.isLoaded = true
	} catch (error) {
		console.log(error)

		/** Handle Temple Wallet Error: 🤦‍♂️ */
		if (error.message === 'An unknown error occured. Please try again or report it') {
			notificationsStore.create({
				kind: 'warning',
				title: `Error detected in the Temple Wallet. Reset permissions and connect again.`,
				visible: true,
			})
		}
	}
}

watch(
	() => Core.base.isAtomexReady,
	() => {
		if (!Core.base.isAtomexReady) return

		setupWallets()
	}
)

useMeta({
	title: 'Atomex DEX App',
	description:
		'Atomex DEX App. 💰 Swap cryptocurrencies on the Atomex exchange directly from your favorite wallets such as Metamask, Temple Wallet, etc. 👛 0% Transaction Fee. ✅ Stay incognito. ⏩ A fully decentralized Cross-Chain exchange.',

	og: {
		title: 'Atomex DEX App',
		description:
			'Atomex DEX App. 💰 Swap cryptocurrencies on the Atomex exchange directly from your favorite wallets such as Metamask, Temple Wallet, etc. 👛 0% Transaction Fee. ✅ Stay incognito. ⏩ A fully decentralized Cross-Chain exchange.',
	},
	twitter: {
		title: 'Atomex DEX App',
		description:
			'Atomex DEX App. 💰 Swap cryptocurrencies on the Atomex exchange directly from your favorite wallets such as Metamask, Temple Wallet, etc. 👛 0% Transaction Fee. ✅ Stay incognito. ⏩ A fully decentralized Cross-Chain exchange.',
	},

	link: [
		{
			rel: 'canonical',
			href: `https://dex.atomex.me`,
		},
	],
})
</script>

<template>
	<div class="app_wrapper">
		<div id="modal" />
		<div id="tooltip" />
		<div id="dropdown" />
		<NotificationsPortal />

		<TheHeader />
		<div class="router_view">
			<router-view v-if="Core.base.isAtomexReady" />
		</div>
		<TheFooter />
	</div>
</template>

<style>
.app_wrapper {
	display: flex;
	flex-direction: column;
	height: 100%;
}

.router_view {
	flex: 1;
}

*::-webkit-scrollbar {
	width: 6px;
}

*::-webkit-scrollbar-track {
	background: rgba(255, 255, 255, 0.1);
}

*::-webkit-scrollbar-thumb {
	background-color: rgba(255, 255, 255, 0.3);
	border-radius: 50px;
}

#app {
	height: 100%;
	overflow-x: hidden;
}

html {
	font-family: 'Inter', sans-serif;
	word-spacing: 1px;
	text-rendering: optimizelegibility;
	-ms-text-size-adjust: 100%;
	-webkit-text-size-adjust: 100%;
	-moz-osx-font-smoothing: grayscale;
	-webkit-font-smoothing: antialiased;
	box-sizing: border-box;
	height: 100vh;

	background: var(--app-bg);
}

*,
*::before,
*::after {
	box-sizing: border-box;
	margin: 0;
}

* {
	touch-action: pan-x pan-y;
}

body {
	overscroll-behavior-x: none;
	overscroll-behavior-y: none;
	user-select: none;
	-webkit-tap-highlight-color: transparent;

	height: 100%;
}

button {
	font: inherit;
	padding: 0;
	border: none;
	outline: none;
}

input {
	font: inherit;
	border: none;
	outline: none;
	background: transparent;
}

a {
	color: inherit;
	text-decoration: none;
}

a,
button {
	touch-action: manipulation;
}

:root {
	--app-bg: #071d3a;

	--primary-900: #1e3e93;
	--primary-800: #2762bd;
	--primary-500: #3293f1;

	--secondary-500: #01a299;
	--secondary-400: #00b3a6;

	--btn-pressed: #0f3088;

	--deep-blue: #0d1421;

	/** On Surface */
	--high-emphasis: rgba(196, 222, 255, 0.97);
	--medium-emphasis: rgba(196, 222, 255, 0.6);
	--low-emphasis: rgba(196, 222, 255, 0.16);
	--ultra-low-emphasis: rgba(196, 222, 255, 0.06);

	--error: rgba(225, 69, 124, 1);
	--alert: rgba(255, 168, 0, 1);
}

.fade-enter-active,
.fade-leave-active {
	transition: all 0.1s ease-out;
}
.fade-enter-from,
.fade-leave-to {
	opacity: 0;
}

.fastfade-enter-active,
.fastfade-leave-active {
	transition: opacity 0.15s ease;
}

.fastfade-enter-from,
.fastfade-leave-to {
	opacity: 0;
}

.dropdown-enter-active,
.dropdown-leave-active {
	animation: fade-in 0.2s;
	transform-origin: top;
}

.popup-enter-active,
.popup-leave-active {
	transition: all 0.1s ease-out;
}
.popup-enter-from,
.popup-leave-to {
	opacity: 0;
	transform: scale(0.95);
}
</style>
