import axios from 'axios'

const priceProviders = {
	binance: 'https://www.binance.com/api/v3/ticker/price?symbol=',
	kraken: 'https://api.kraken.com/0/public/Ticker?pair=',
}

export const fetchPrice = async (base, quote, provider = 'binance') => {
	try {
		const { data } = await axios.get(`${priceProviders[provider]}${base}${quote}`)

		if (provider === 'binance') {
			return Number(data.price).toFixed(2)
		} else if (provider === 'kraken') {
			const symbol = Object.keys(data.result)[0]
			return data.result[symbol].c[0]
		} else {
			console.warn('Unknown price provider')
			return 0
		}
	} catch (error) {
		console.log(error)
	}
}
