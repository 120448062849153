import { defineStore } from 'pinia'
import { v4 as uuid } from 'uuid'

export const useNotificationsStore = defineStore({
	id: 'notifications',

	state() {
		return {
			notifications: [],
		}
	},
	actions: {
		create(notification) {
			const id = uuid()

			/**
			 * Maximum 3 notifications
			 */
			if (this.notifications.length > 3) {
				this.notifications.pop()
			}

			this.notifications.push({ ...notification, id })

			if (!notification.visible)
				setTimeout(() => {
					this.destroy(id)
				}, 5500)
		},
		destroy(id) {
			this.notifications = this.notifications.filter((notification) => notification.id !== id)
		},
	},
})
