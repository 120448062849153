import axios from 'axios'

export const fetchTokenBalanceByAccount = async ({ tokenContract, accountAddress, atomexNetwork }) => {
	try {
		const network = atomexNetwork === 'mainnet' ? 'mainnet' : 'ghostnet'

		const { data } = await axios.get(
			`https://api.${network}.tzkt.io/v1/tokens/balances?account=${accountAddress}&token.contract=${tokenContract}`
		)

		if (!data.length) return 0

		return parseFloat(data[0].balance) / 1000000
	} catch (error) {
		console.log(error)
	}
}
