<script setup>
import { ref, reactive } from 'vue'

const props = defineProps({
	width: {
		type: String,
		default: '260',
	},
	disabled: {
		type: Boolean,
	},
})

const show = ref(false)

const trigger = ref(null)
const coordinates = reactive({ x: 0, y: 0 })

const handleHover = () => {
	if (props.disabled) return

	const triggerRect = trigger.value.getBoundingClientRect()

	coordinates.x = triggerRect.x
	coordinates.y = triggerRect.y + triggerRect.height + 8

	show.value = true
}

const handleBlur = () => {
	show.value = false
}
</script>

<template>
	<div :class="$style.wrapper">
		<div ref="trigger" @mouseenter="handleHover" @mouseleave="handleBlur" :class="$style.trigger">
			<slot />
		</div>

		<teleport to="#tooltip">
			<transition name="popup">
				<div
					v-if="show"
					:style="{ transform: `translate(${coordinates.x}px, ${coordinates.y}px)`, width: `${width}px` }"
					:class="$style.content"
				>
					<slot name="content" />
				</div>
			</transition>
		</teleport>
	</div>
</template>

<style module>
.wrapper {
	display: flex;
}

.trigger {
	display: flex;
}

.content {
	position: absolute;

	top: 0;
	left: 0;
	z-index: 1001;

	width: 260px;
	background: var(--deep-blue);
	box-shadow: 1px 4px 10px rgba(13, 20, 33, 0.25);
	border-radius: 12px;
	padding: 12px 20px;

	font-size: 14px;
	line-height: 20px;
	font-weight: 400;
	color: var(--high-emphasis);
}
</style>
